textarea {
  height: 1em;
  max-height: 3em;
  overflow-y: auto;
}

* {
  /*font-size: .95rem;*/
	font-family: 'IBM Plex Sans', sans-serif;
	letter-spacing: 0.001em;
	/*font-weight: 300;*/
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}
