@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background: #eee;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background: #bbb;
  border: 0px;
}

* {
	font-family: 'IBM Plex Sans', sans-serif;
	letter-spacing: 0.001em;
	/*font-weight: 300;*/
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}
